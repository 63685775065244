<!--
    Created by 程雨喵'mac on 2023/12/29.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：登录页-登录表单
-->
<style lang="less">
@import '../css/loginCommon';
.login-form {
  padding: 40px 40px 30px;
  width: @loginFormWidth;
  box-sizing: border-box;
  background-color: rgba(255,255,255,0.5);
  border-radius: @loginFormHeight * 0.5;
  backdrop-filter: blur(15px);
  box-shadow: 0 0 20px 8px rgba(84,110,253,0.1);
  border: 2px solid white;
  &:hover, &:focus-within {
    border-color: @loginThemeColor;
  }
  h1 {
    margin-bottom: 28px;
    font-size: 19px;
    color: @loginThemeColor;
    text-align: center;
    line-height: 21px;
    text-shadow: 2px 2px 4px rgba(84,110,253, 0.3);
  }
  .form-item {
    position: relative;
    .display-flex();
    .input-code-img-big {
      position: absolute;
      padding: 10px;
      .display-flex();
      bottom: 45px;
      background-color: rgba(255,255,255,0.8);
      border-radius: @borderRadiusMid;
      img {
        height: 100px;
      }
    }
    &+.form-item {
      margin-top: 20px;
    }
    .form-inner {
      flex: 1;
      .display-flex();
      height: @loginFormHeight;
      background-color: white;
      border-radius: @loginBorderRadius;
      overflow: hidden;
      border: 1px solid @loginBorderColor;
      &:hover, &:focus-within {
        border-color: @loginThemeColor;
      }
      .item-left {
        .display-flex();
        .flex-grow(40px);
        height: 16px;
        border-right: 1px solid @loginBorderColor;
        img {
          width: 14px;
        }
      }
      .item-right {
        .display-flex();
        justify-content: flex-start;
        flex: 1;
        min-width: 100px;
        height: 100%;
        font-size: 13px;
        input {
          padding: 0 10px 0 15px;
          flex: 1;
          min-width: 100px;
          height: @loginFormHeight;
          border: 0 !important;
          outline: none !important;
        }
      }
    }
    .form-code {
      width: 100px;
      padding: 0 5px;
      margin-left: 10px;
      .display-flex();
      height: @loginFormHeight;
      background-color: #f5f5f5;
      border-radius: @loginBorderRadius;
      overflow: hidden;
      border: 1px solid @loginBorderColor;
      cursor: pointer;
      color: @textColorLight;
      &:hover {
        border-color: @loginThemeColor;
        .ivu-icon {
          color: @loginThemeColor;
        }
      }
      .code-img-outer {
        .display-flex();
        .flex-grow(70px);
        height: @loginFormHeight - 8px;
        border-radius: @borderRadiusMin;
        overflow: hidden;
        &.error-img {
          .flex-grow(@loginFormHeight * 0.6);
          height: auto;
        }
        img {
          //.flex-grow(78px);
          height: @loginFormHeight;
        }
      }
      .refresh-icon {
        .flex-grow(20px);
        .display-flex();
        .ivu-icon {
          font-size: 16px;
        }
      }
    }
  }
  .other-btn {
    margin-top: @containerGap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //height: 60px;
    color: @textColorLight;
    font-size: 14px;
    .ivu-checkbox-checked {
      .ivu-checkbox-inner {
        border-color: @loginThemeColor;
        background-color: @loginThemeColor;
      }
    }
    .forget {
      cursor: pointer;
    }
  }
  .login-btn {
    margin-top: 20px;
    line-height: @loginFormHeight;
    border-radius: @borderRadiusMin;
    background-color: @loginThemeColor;
    color: white;
    font-size: 15px;
    text-align: center;
    cursor: pointer;
  }
  .version {
    margin-top: 5px;
    text-align: center;
    color: rgba(0,0,0,0.5);
    font-size: 12px;
  }
}
</style>

<template>
  <div class="login-form">
    <h1>欢迎登录智能充电管理平台</h1>
    <div
      v-for="(item, i) in formItems"
      :key="i"
      class="form-item"
    >
      <div class="form-inner">
        <div class="item-left">
          <img :src="`/loginImg/${item.icon}.png`">
        </div>
        <div class="item-right">
          <input
            v-model="form[item.key]"
            :type="item.type"
            :placeholder="item.title"
            :maxlength="item.maxLength"
            @focus="onInputFocus(item.key)"
            @blur="onInputBlur"
            @keydown.enter="_clickLogin"
          >
        </div>
      </div>
      <div
        v-if="item.key === 'verifyInputValue'"
        class="form-code"
        @click.stop="_clickCode"
      >
        <div
          v-if="showBigImgCode"
          class="input-code-img-big"
        >
          <img
            :src="form.verifyUrl || '/icon/img_error.png'"
          >
        </div>
        <div
          :class="form.verifyUrl ? '' : 'error-img'"
          class="code-img-outer"
        >
          <img
            :src="form.verifyUrl || '/icon/img_error.png'"
          >
        </div>
        <div class="refresh-icon">
          <Icon type="md-refresh" />
        </div>
      </div>
    </div>
    <div class="other-btn">
      <Checkbox
        v-model="rememberPassword"
      >
        记住密码
      </Checkbox>
      <span
        class="forget"
        @click.stop="_clickForget"
      >忘记密码？</span>
    </div>
    <div
      class="login-btn"
      @click.stop="_clickLogin"
    >
      登录
    </div>
    <div class="version">
      v{{ version }}
    </div>
  </div>
</template>

<script>
import UserApiSet from '@/request/api/UserApiSet';
import md5 from 'md5';
export default {
  components: {},
  data () {
    return {
      form: {
        account: '',
        password: '',
        verifyInputValue: '',
        verifyUrl: '',
        verifyId: '',
        verifyCodeLength: '4' // 默认是4
      },
      formItems: [
        { type: 'text', key: 'account', title: '账号', icon: 'login_user', maxLength: 30 },
        { type: 'password', key: 'password', title: '密码', icon: 'login_pwd', maxLength: 50 },
        { type: 'text', key: 'verifyInputValue', title: '图形验证码', icon: 'login_code', maxLength: 4 }
      ],
      rememberPassword: false,
      loading: false,
      version: '',
      showBigImgCode: false
    };
  },
  created () {
    console.log('login-form-created ====================>');
    this.$nextTick(() => {
      this.rememberPassword = this.$storage.savePassword;
      if (this.rememberPassword) {
        const dic = this.$storage.usernamePassword;
        this.form.account = dic.account;
        this.form.password = dic.password;
      }
      this.version = this.$config.version;
      this.requestCode();
    });
  },
  methods: {
    onInputFocus (e) {
      if (e === 'verifyInputValue') {
        this.showBigImgCode = true;
      }
    },
    onInputBlur () {
      this.showBigImgCode = false;
    },
    _clickLogin () {
      if (this.loading) return;
      const form = this.form;
      if (form.account && form.password && form.password.length >= 1) {
        if (!form.verifyInputValue) return this.$Message.info('请输入验证码');
        this.$storage.savePassword = this.rememberPassword;
        if (this.rememberPassword) {
          this.$storage.usernamePassword = {
            account: this.form.account,
            password: this.form.password
          };
        } else {
          this.$storage.usernamePassword = null;
        }
        this.requestLogin();
      } else {
        this.$Message.info('账号或密码错误');
      }
    },
    _clickCode () {
      this.requestCode();
    },
    _clickForget () {
      this.$emit('on-forget');
    },
    // 外部调用
    $quickLogin ({ account, password }) {
      this.form.account = account;
      this.form.password = password;
    },
    requestLogin () {
      this.loading = true;
      // this.$storage.token = '123';
      // this.$storage.user = {
      //   userId: '1',
      //   username: this.username
      // };
      // this.$router.replace({ name: 'dashboard_index' });
      const form = this.form;
      const api = UserApiSet.loginApi;
      api.params = {
        username: form.account,
        password: md5(form.password),
        code: form.verifyInputValue,
        uuid: form.verifyId
      };
      this.$http(api).then(res => {
        const resData = res.data;
        this.$storage.adminLoginList = { username: api.params.username, password: api.params.password };
        this.$storage.token = resData.accessToken;
        this.$storage.user = {
          userId: '1',
          username: this.username
        };
        this.loading = false;
        this.requestUserInfo();
      }).catch(e => {
        this.loading = false;
        this._clickCode();
      });
    },
    // 请求个人信息
    requestUserInfo () {
      const api = UserApiSet.personalInfo;
      this.$http(api).then(res => {
        const { data } = res;
        const adminUser = data.adminUser;
        if (adminUser) {
          let merchantInfo = {};
          if (adminUser.userType === this.$config.systemUserType.admin) {
            merchantInfo = { providerName: '智能充电管理平台' };
            adminUser.h5UserTypeName = '平台管理员';
          } else if (adminUser.userType === this.$config.systemUserType.service || ((adminUser.userType - 1) === this.$config.systemUserType.service)) {
            merchantInfo = data.serviceProvider || {};
            adminUser.h5UserTypeName = '服务商';
          } else if (adminUser.userType === this.$config.systemUserType.device || ((adminUser.userType - 1) === this.$config.systemUserType.device)) {
            merchantInfo = data.deviceProvider || {};
            adminUser.h5UserTypeName = '设备商';
          } else if (adminUser.userType === this.$config.systemUserType.property || ((adminUser.userType - 1) === this.$config.systemUserType.property)) {
            merchantInfo = data.propertyCompany || {};
            adminUser.h5UserTypeName = '物业公司';
          }
          // console.log(merchantInfo);
          this.$storage.user = adminUser;
          this.$storage.merchantInfo = merchantInfo;
          this.requestRouters();
        } else {
          this.$Modal.confirm({
            title: '登录失败',
            content: '获取登录信息失败，请联系管理员',
            cancelText: ' ',
            closable: false
          });
        }
      });
    },
    // 请求权限
    requestRouters () {
      const api = UserApiSet.routers;
      api.params = {};
      this.$http(api).then(res => {
        const { data } = res;
        if (Array.isArray(data) && data.length) {
          const level1 = [];
          const level2 = [];
          if (this.$config.isDev) {
            console.error('开发阶段，权限写死, 提测后一起弄');
            console.error('开发阶段，权限写死, 提测后一起弄');
            console.error('开发阶段，权限写死, 提测后一起弄');
            // level1.push('operations');
            level2.push(...['dataStatistics_deviceAnalysis', 'dataStatistics_communityAnalysis', 'dataStatistics_officeAnalysis', 'dataStatistics_offlineLongTimeAnalysis', 'dataStatistics_offlineAlwaysAnalysis']);
          }
          data.forEach(v => {
            level1.push(v.path);
            // console.error('开发阶段，权限写死 outside， 后面要改正！！！！！');
            if (v.children) {
              v.children.forEach(vv => {
                level2.push(vv.meta.title);
              });
            }
          });
          this.$storage.leftMenus = {
            level1, level2
          };
          this.$router.replace({ name: 'dashboard_index' });
        } else {
          this.$Message.warning('无权限登录此系统');
        }
      });
    },
    requestCode () {
      const api = UserApiSet.captchaApi;
      this.$http(api).then(res => {
        console.log(res);
        const resData = res.data;
        this.form.verifyUrl = 'data:image/png;base64,' + resData.image;
        this.form.verifyId = resData.uuid;
        this.form.verifyInputValue = '';
      });
    }
  }
};
</script>
